import { Directive, AfterViewInit, ElementRef } from '@angular/core';

declare var vl: any;

@Directive({
  selector: '[appWuStickyDirective]'
})
export class WuStickyDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    vl.stickyBar.dress(this.elementRef.nativeElement);
  }
}
