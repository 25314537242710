import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import  config  from 'assets/configurations/config.json';
import { Directory } from 'app/shared/directory/directory';
import { Notification } from 'app/shared/directory/notification';
import { SharepointFile } from 'app/shared/directory/sharepoint-file';
import { Recipient } from 'app/shared/directory/recipient';
import { WorkingDirectory } from 'app/core/working-directory';
import { SortingOrder } from 'app/shared/directory/sorting-order';

@Injectable({
  providedIn: 'root'
})
export class FileExchangeService {

  constructor(private http: HttpClient) { }

  getGeneralFileExchangeUrl(): Observable<WorkingDirectory> {
    return this.http.get<WorkingDirectory>(config.portalApiEndpoint + `fileexchange/generalFileExchange`);
  }

  getGeneralFileExchangeUrlByActorId(actorId: string): Observable<WorkingDirectory> {
    return this.http.get<WorkingDirectory>(config.portalApiEndpoint + `fileexchange/generalFileExchange?actorId=${actorId}`);
  }

  getDirectoryList(directoryUrl: string,
    baseUrl: string,
    includeFiles: boolean = false,
    sortingOrder = SortingOrder.LastModified
  ): Observable<Directory[]> {
    return this.http
      .get<Directory[]>(config.fileexchangeApiEndpoint + `fileexchange/folders`,
      {
        params: { 'folderUrl': encodeURIComponent(directoryUrl),
                  'baseUrl': baseUrl,
                  'includeFiles': `${includeFiles}`,
                  'sortingOrder': `${sortingOrder}` }
      });
  }

  getFolderDetails(directoryUrl: string, baseUrl: string): Observable<Directory> {
    return this.http
      .get<Directory>(config.fileexchangeApiEndpoint + `fileexchange/folder`,
      {
        params: { 'folderUrl': encodeURIComponent(directoryUrl),
                  'baseUrl': baseUrl }
      });
  }

  getFileList(directoryUrl: string, baseUrl: string): Observable<SharepointFile[]> {
    return this.http
      .get<SharepointFile[]>(config.fileexchangeApiEndpoint + `fileexchange/files`,
      {
        params: { 'folderUrl': encodeURIComponent(directoryUrl),
                  'baseUrl': baseUrl }
      });
  }

  downloadFile(fileUrl: string, baseUrl: string) {
    return this.http
      .get(config.fileexchangeApiEndpoint + `fileexchange/files/download`,
        {
          params: { 'fileUrl': encodeURIComponent(fileUrl),
                    'baseUrl': baseUrl },
          responseType: 'blob'
        });
  }

  downloadFolder(folderUrl: string, baseUrl: string) {
    return this.http
      .get(config.fileexchangeApiEndpoint + `fileexchange/folder/download`,
        {
          params: { 'folderUrl': encodeURIComponent(folderUrl),
                    'baseUrl': baseUrl },
          responseType: 'blob'
        });
  }

  downloadFolders(folderUrl: string, baseUrl: string) {
    return this.http
      .get(config.fileexchangeApiEndpoint + `fileexchange/folders/download`,
        {
          params: { 'folderUrl': encodeURIComponent(folderUrl),
                    'baseUrl': baseUrl },
          responseType: 'blob'
        });
  }

  createDirectory(directory: Directory): Observable<Directory> {
    return this.http.post<Directory>(config.fileexchangeApiEndpoint + 'fileexchange/folders', directory);
  }

  uploadFile(fileUrl: string, baseUrl: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('fileUrl', fileUrl);
    formData.append('baseUrl', baseUrl);

    return this.http.post(config.fileexchangeApiEndpoint + `fileexchange`, formData);
  }

  updateDirectory(directory: Directory): Observable<Directory> {
    return this.http.put<Directory>(config.fileexchangeApiEndpoint + 'fileexchange/folders', directory);
  }

  deleteFile(fileUrl: string, baseUrl: string): Observable<any> {
    return this.http
      .delete<SharepointFile[]>(config.fileexchangeApiEndpoint + `fileexchange/files`,
      {
        params: { 'fileUrl': encodeURIComponent(fileUrl),
                  'baseUrl': baseUrl }
      });
  }

  deleteFolder(folderUrl: string, baseUrl: string): Observable<any> {
    return this.http
      .delete<SharepointFile[]>(config.fileexchangeApiEndpoint + `fileexchange/folders`,
      {
        params: { 'folderUrl': encodeURIComponent(folderUrl),
                  'baseUrl': baseUrl }
      });
  }

  getPortalEmployees(): Observable<Recipient[]> {
    return this.http.get<Recipient[]>(config.portalApiEndpoint + `fileexchange/portalemployees`);
  }

  getPortalTeams(): Observable<Recipient[]> {
    return this.http.get<Recipient[]>(config.portalApiEndpoint + `fileexchange/portalteams`);
  }

  getPortalUsers(actorId: string): Observable<Recipient[]> {
    return this.http.get<Recipient[]>(config.portalApiEndpoint + `fileexchange/portalusers/${actorId}`);
  }

  sendMessage(uploadMessage: Notification): Observable<any> {
    return this.http.post(config.portalApiEndpoint + `fileexchange/notification/general`, uploadMessage);
  }

  sendMessageByActorId(uploadMessage: Notification, actorId: string): Observable<any> {
    return this.http.post(config.portalApiEndpoint + `fileexchange/notification/general?actorId=${actorId}`, uploadMessage);
  }

  sendControlProjectNotification(uploadMessage: Notification, controlProjectId: string): Observable<any> {
    return this.http.post(config.portalApiEndpoint + `fileexchange/notification/controlproject/${controlProjectId}`, uploadMessage);
  }
}
