import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, ParamMap, Route } from '@angular/router';

import { Observable } from 'rxjs';
import { BrowserStorageHelper } from 'app/shared/helpers/browser-storage.helper';
import { AuthService } from 'app/core/auth.service';


@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private router: Router,
        private authService: AuthService,
        private browserStorageHelper: BrowserStorageHelper) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const url = state.url.split('?')[0];
        return this.isAllowed(url, route.queryParamMap);
    }

    canLoad(route: Route): Observable<boolean> | boolean {
        const url = `/${route.path}`;
        return this.isAllowed(url);
    }

    private isAllowed(url: string, queryParamMap?: ParamMap): Observable<boolean> | boolean {
        console.log('AuthGuard, isAllowed url; ' + url);

        const hasValidToken = this.authService.isSignedIn();
        console.log('AuthGuard, isAllowed: ' + hasValidToken);
        if (!hasValidToken) {
            if (this.browserStorageHelper.getRedirectUrl() === null) {
                this.browserStorageHelper.setRedirectUrl(url);
                this.browserStorageHelper.setRedirectQueryParams(queryParamMap);
            }
            this.authService.signIn();
        }

        if (url !== '/user/selection' && url !== '/user/activation') {
            const user = this.browserStorageHelper.getUser();
            if (!user) {
                this.router.navigate(['/user/selection']);
            }
        }

        return hasValidToken;
    }
}
