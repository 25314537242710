import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ForbiddenComponent } from 'app/forbidden.component';
import { UnauthorizedComponent } from 'app/unauthorized.component';
import { CallbackComponent } from 'app/callback.component';
import { NotfoundComponent } from 'app/notfound.component';
import { ModuleGuard } from 'app/core/module-guard';

const routes: Routes = [
  {
    path: 'bestuursvergaderingen',
    loadChildren: () => import('app/board-meeting/board-meeting.module').then(m => m.BoardMeetingModule),
    canLoad: [ModuleGuard]
  },
  {
    path: 'overheidsopdrachten',
    loadChildren: () => import('app/public-procurement/public-procurement.module').then(m => m.PublicProcurementModule),
    canLoad: [ModuleGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('app/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'bestuur',
    loadChildren: () => import('app/board/board.module').then(m => m.BoardModule),
    canLoad: [ModuleGuard]
  },
  {
    path: 'globaleonderzoeken',
    loadChildren: () => import('app/control-project/control-project.module').then(m => m.ControlProjectModule),
    canLoad: [ModuleGuard]
  },
  {
    path: 'bestandsuitwisseling',
    loadChildren: () => import('app/general-file-exchange/general-file-exchange.module').then(m => m.GeneralFileExchangeModule),
    canLoad: [ModuleGuard]
  },
  {
    path: '',
    loadChildren: () => import('app/home/home.module').then(m => m.HomeModule)
  },
  { path: 'callback', component: CallbackComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
