import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

import FileSaver from 'file-saver';

import { CorrelationTokenService } from 'app/core/correlation-token.service';
import { ContentBlock, DocumentTemplate } from 'app/shared/content-block/content-block';
import { ContentBlockService } from 'app/shared/content-block/content-block-service';

@Component({
  selector: 'app-content-block',
  templateUrl: './content-block.component.html'
})
export class ContentBlockComponent implements OnInit {
  @Input() contentBlock: ContentBlock;
  templateIdBeingDownloaded: DocumentTemplate = null;
  isInformation = true;
  isAlert = false;
  errorOccurred = false;
  correlationId: string;
  errorMessage: string;

  constructor(private correlationTokenService: CorrelationTokenService,
              private contentBlockService: ContentBlockService) {
  }

  ngOnInit() {
    switch (this.contentBlock.type) {
      case 1:
        this.isInformation = true;
        this.isAlert = false;
        break;

      case 2:
        this.isInformation = false;
        this.isAlert = true;
        break;

      default:
        this.isAlert = false;
        this.isInformation = true;
        break;
    }
  }

  downloadTemplate(documentTemplate: DocumentTemplate) {
    if (this.templateIdBeingDownloaded === null) {
      this.templateIdBeingDownloaded = documentTemplate;
      this.correlationId = this.correlationTokenService.setToken();
      this.contentBlockService.getDocument(this.templateIdBeingDownloaded.id).subscribe({
        next: blob => {
          FileSaver.saveAs(blob, this.templateIdBeingDownloaded.fileName);
          this.templateIdBeingDownloaded = null;
        },
        error: () => {
          // tslint:disable-next-line:max-line-length
          this.errorMessage = `Er is iets misgelopen tijdens het downloaden van dit bestand.<br /> Neem contact met ons op via <a href="mailto:toezicht.wonen@vlaanderen.be?subject=Fout bij het laden van pagina '${this.correlationId}'">toezicht.wonen@vlaanderen.be</a> als dit probleem zich blijft voordoen en vermeld dan volgende referentie: ${this.correlationId}.`;
          this.errorOccurred = true;
        }
      });
    }
  }
}
