import { Injectable } from '@angular/core';

@Injectable()
export class CodeTableHelper {
  getCodeById(arr, id) {
    for (let d = 0, len = arr.length; d < len; d += 1) {
      if (arr[d].id === id) {
        return arr[d].code;
      }
    }
    return null;
  }

  getIdByCode(arr, code) {
    for (let d = 0, len = arr.length; d < len; d += 1) {
      if (arr[d].code === code) {
        return arr[d].id;
      }
    }
    return null;
  }

  public getNameById(arr, id): string {
    for (let d = 0, len = arr.length; d < len; d += 1) {
      if (arr[d].id === id) {
        return arr[d].name;
      }
    }
    return null;
  }
}
