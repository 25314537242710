<nav appWuSidenav class="vl-side-navigation js-vl-side-navigation js-vl-sticky js-vl-scrollspy" data-vl-side-navigation
 data-vl-side-navigation-scrollable data-vl-scrollspy data-vl-scrollspy-mobile="Componenten" data-vl-sticky
 data-vl-sticky-offset-top="25" aria-label="inhoudsopgave detail">
  <div class="vl-side-navigation__content">
    <ul class="vl-side-navigation__group">
      <li *ngFor="let title of titleList" class="vl-side-navigation__item vl-side-navigation__item--parent">
        <a class="vl-side-navigation__toggle" (click)="scrollTo(title.id)" >
          <p>{{title.title}}</p>
          <i class="vl-vi vl-vi-arrow-right-fat"></i>
        </a>
      </li>
    </ul>
  </div>
</nav>
