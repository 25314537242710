import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BrowserStorageHelper } from 'app/shared/helpers/browser-storage.helper';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private browserStorageHelper: BrowserStorageHelper;

    constructor(private injector: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;
        if (this.browserStorageHelper === undefined) {
            this.browserStorageHelper = this.injector.get(BrowserStorageHelper);
        }
        if (this.browserStorageHelper !== undefined) {
            const token = this.browserStorageHelper.getIdToken();
            if (token !== null) {
                const tokenValue = `Bearer ${token}`;
                requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
            }
        } else {
            console.log('BrowserStorageHelper undefined: NO auth header!');
        }

        return next.handle(requestToForward);
    }
}
