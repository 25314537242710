import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import  config  from 'assets/configurations/config.json';
import { ContentBlock } from 'app/shared/content-block/content-block';

@Injectable()
export class ContentBlockService {
  constructor(private http: HttpClient) { }

  getContentBlocks(name: string): Observable<ContentBlock[]> {
    return this.http.get<ContentBlock[]>(config.portalApiEndpoint + `contentblock/${name}`);
  }

  getDocument (id: string) {
    return this.http
      .get(config.portalApiEndpoint + `contentblock/template/${id}`,
        { responseType: 'blob' });
  }
}
