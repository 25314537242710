import { NgModule, ErrorHandler, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { throwIfAlreadyLoaded } from 'app/core/module-import-guard';
import { AuthInterceptor } from 'app/core/auth-interceptor';
import { FormHelper } from 'app/core/form-helper';
import { CustomValidators } from 'app/core/custom-validators';
import { DateHelper } from 'app/core/date-helper';
import { GlobalErrorHandler } from 'app/core/global-error-handler';
import { LoggingService } from 'app/core/logging.service';
import { CodeTableService } from 'app/core/code-table.service';
import { CodeTableHelper } from 'app/core/code-table-helper';
import { HeaderInterceptor } from 'app/core/header.interceptor';
import { CorrelationTokenService } from 'app/core/correlation-token.service';
import { AuthService } from 'app/core/auth.service';
import { AuthGuard } from 'app/core/auth-guard';
import { ModuleGuard } from 'app/core/module-guard';
import { ComponentGuard } from 'app/core/component-guard';
import { SharedModule } from 'app/shared/shared.module';
import { RetryInterceptor } from 'app/core/retry-interceptor';
import { ActorTypeService } from 'app/core/actor-type.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  // declarations: []
  providers: [
    AuthGuard,
    ModuleGuard,
    ComponentGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: ErrorHandler,      useClass: GlobalErrorHandler },
    // TODO check if AuthInterceptor and HeaderInterceptor need to be merged...
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    FormHelper,
    CustomValidators,
    CorrelationTokenService,
    DateHelper,
    LoggingService,
    CodeTableService,
    CodeTableHelper,
    AuthService,
    ActorTypeService
  ]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
