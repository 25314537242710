import { FormControl, ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';

import moment from 'moment';

import { User } from 'app/core/user';
import { Directory } from 'app/shared/directory/directory';
import { ValidationContext } from 'app/core/validation-context';

export class CustomValidators {

  public dateBeforeValidator(endDate: moment.Moment): ValidatorFn {
    return (c: FormControl): {[key: string]: any} | null => {
      const pickedDate = moment(c.value, 'DD/MM/YYYY');

      if (!pickedDate.isValid || pickedDate.isAfter(endDate)) {
        return {
          dateBeforeValidator: {
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  public dateAfterValidator(beginDate: moment.Moment): ValidatorFn {
    return (c: FormControl): {[key: string]: any} | null => {
      const pickedDate = moment(c.value, 'DD/MM/YYYY');

      if (!pickedDate.isValid ||  pickedDate.isBefore(beginDate)) {
        return {
          dateAfterValidator: {
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  public dateBeforeEndNextYearValidator(): ValidatorFn {
    return (c: FormControl): {[key: string]: any} | null => {
      const pickedDate = moment(c.value, 'DD/MM/YYYY');
      const today = moment();
      const endNextYear = moment([today.year() + 1, 11, 31]); // months are zero-indexed, other two one-indexed, hence the 11

      if (!pickedDate.isValid ||  pickedDate > endNextYear) {
        return {
          dateBeforeEndNextYearValidator: {
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  public dateFormatValidator(format: string = 'D/M/YYYY'): ValidatorFn {
    return (c: FormControl): {[key: string]: any} | null => {
      if (c.value === null || c.value === '') {
        return null;
      } else {
        const isCorrectFormat = moment(c.value, format, true).isValid();

        if (isCorrectFormat) {
          return null;
        } else {
          return {
            dateFormatValidator: {
              valid: false
            }
          };
        }
      }
    };
  }

  public dateInWindowValidator(startYear: number, endYear: number, format: string = 'D/M/YYYY'): ValidatorFn {
    return (c: FormControl): {[key: string]: any} | null => {
      if (c.value === null || c.value === '') {
        return null;
      } else {
        const startDate = moment(`1/1/${startYear}`, format);
        const endDate = moment(`31/12/${endYear}`, format);
        const date = moment(c.value, format);

        if (date.isBetween(startDate, endDate)) {
          return null;
        } else {
          return {
            dateInWindowValidator: {
              valid: false
            }
          };
        }
      }
    };
  }

  public timeFormatValidator(format: string = 'HH:mm'): ValidatorFn {
    return (c: FormControl): {[key: string]: any} | null => {
      if (c.value === null || c.value === '') {
        return null;
      } else {
        const isCorrectFormat = moment(c.value, format, true).isValid();

        if (isCorrectFormat) {
          return null;
        } else {
          return {
            timeFormatValidator: {
              valid: false
            }
          };
        }
      }
    };
  }

  public moreThanNumberOfMonthsAgoValidator(date: moment.Moment, today: moment.Moment, months: number): ValidatorFn {
    return (c: FormGroup): {[key: string]: any} | null => {
      if (today.subtract(months, 'month') < date) {
        return {
          moreThanNumberOfMonthsAgoValidator: {
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  public publicProcurementFormGroupValidator(): ValidatorFn {
    return (c: FormGroup): {[key: string]: any} | null => {
      const error: any = {};
      const finalEstimate = c.get('finalEstimate');
      const order = c.get('order');
      const datesCorrect = this.publicProcurementsDatesOrderIsCorrect(c);

      if ((finalEstimate.value === null || finalEstimate.value === undefined || finalEstimate.value === '')
          && (order.value === null || order.value === undefined || order.value === '')) {
          error.finalEstimateOrOrder = {valid: false };
      }

      if (!datesCorrect) {
        error.dateOrder = { valid: false };
      }

      return error;
    };
  }

  private publicProcurementsDatesOrderIsCorrect(formGroup: FormGroup) {
    const publicationDate = formGroup.get('publicationDate').value;
    const finalSubmissionDate = formGroup.get('finalSubmissionDate').value;
    const allocationDate = formGroup.get('allocationDate').value;
    const orderLetterDate = formGroup.get('orderLetterDate').value;
    const startDate = formGroup.get('startDate').value;

    let dates = [
                    publicationDate,
                    finalSubmissionDate,
                    allocationDate,
                    orderLetterDate,
                    startDate
                  ];

    dates = dates.filter(this.notEmpty);

    const dateIncorrect = dates.some((date, index) => {
      return moment(date, 'DDMMYYYY') > moment(dates[index + 1], 'DDMMYYYY');
    });

    return !dateIncorrect;
  }

  private notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  public containsIllegalCharactersValidator(illegalCharacters: string): ValidatorFn {
    return (c: FormControl): {[key: string]: any} | null => {
      const string = c.value;

      const illegalCharacterArray = illegalCharacters.split('');
      for (const char of illegalCharacterArray) {
        if (string.indexOf(char) !== -1) {
          return {
            containsIllegalCharacters: {
              valid: false
            }
          };
        }
      }
      return null;
    };
  }

  public validateFile(file: File, user: User, context: ValidationContext): {} {
    const validationErrors: object = {};


    if (this.hasWrongExtension(file.name, user.parameters.allowedExtensions)) {
      validationErrors['hasWrongExtension'] = true;
    } else if (this.containsIllegalCharacters(file.name, user.parameters.illegalCharacters)) {
      validationErrors['containsIllegalCharacters'] = true;
    }

    if (this.stringIsTooLong(file.name, user.parameters.maxFilecharacters)) {
      validationErrors['stringIsTooLong'] = true;
    }

    if (context === ValidationContext.BoardMeeting) {
      if (this.fileIsTooBig(file.size, user.parameters.maxBoardMeetingUploadSizeBytes)) {
        validationErrors['fileTooBig'] = true;
      }
    } else {
      if (this.fileIsTooBig(file.size, user.parameters.maxUploadSizeBytes)) {
        validationErrors['fileTooBig'] = true;
      }
    }

    if (Object.getOwnPropertyNames(validationErrors).length === 0) {
      return null;
    }
    return validationErrors;
  }

  public newDirectoryFormGroupValidator(): ValidatorFn {
    return (c: FormGroup): {[key: string]: any} | null => {

      const employee = c.get('employee');
      const team = c.get('team');

      if (employee.value.length === 0 && team.value.length === 0) {
        return {
          employeeOrTeam: {
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  public nameIsNotUniqueValidator(directories: Directory[]): ValidatorFn {
    return (c: FormControl): {[key: string]: any} | null => {
      const title = c.value.toString();

      if (directories.find( d => d.name.toLowerCase() === title.toLowerCase())) {
        return {
          nameIsNotUnique: {
            valid: false
          }
        };
      }

      return null;
    };
  }


  private containsIllegalCharacters(string: string, illegalCharacters: string): boolean {
    const extensionSeparatorIndex = string.lastIndexOf('.');
    const fileName = string.slice(0, extensionSeparatorIndex - 1);
    const illegalCharacterArray = illegalCharacters.split('');
    let containsIllegalCharacters = false;
    for (const char of illegalCharacterArray) {
      if (fileName.indexOf(char) !== -1) {
        containsIllegalCharacters = true;
        break;
      }
    }
    return containsIllegalCharacters;
  }

  private hasWrongExtension(string: string, allowedExtensions: string): boolean {
    const extensionArray = allowedExtensions.split(';');
    const fileExtension = ((string.split('.')).reverse())[0];

    const extensionFound = extensionArray.some((extension: string) => {
      return extension.toLowerCase() === fileExtension.toLowerCase();
    });

    return !extensionFound;
  }

  private stringIsTooLong(string: string, maxLength: number): boolean {
    if (string.length > maxLength) {
      return true;
    }
    return false;
  }

  private fileIsTooBig(fileSize: number, maxFilesize: number): boolean {
    if (fileSize >= maxFilesize) {
      return true;
    }
    return false;
  }
}
