import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { BrowserStorageHelper } from 'app/shared/helpers/browser-storage.helper';


@Injectable()
export class ModuleGuard implements CanLoad {

  constructor(private router: Router,
    private browserStorageHelper: BrowserStorageHelper) { }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    const user = this.browserStorageHelper.getUser();

    if (user === null || user.userContext === null) {
      return true;
    }

    switch (route.path) {
      case 'bestuursvergaderingen': {
        if (!user.userContext.privilege.bestuursvergaderingen && !user.userContext.privilege.bestuursvergaderingenToewijzingscomite) {
          this.router.navigate(['/forbidden']);
          return false;
        }
        return true;
      }

      case 'overheidsopdrachten': {
        if (!user.userContext.privilege.overheidsopdrachten) {
          this.router.navigate(['/forbidden']);
          return false;
        }
        return true;
      }

      case 'bestuur': {
        if (!user.userContext.privilege.bestuur && !user.userContext.privilege.kapitaalstructuur) {
          this.router.navigate(['/forbidden']);
          return false;
        }
        return true;
      }

      case 'globaleonderzoeken': {
        if (!user.userContext.privilege.globaalOnderzoek) {
          this.router.navigate(['/forbidden']);
          return false;
        }
        return true;
      }

      case 'bestandsuitwisseling': {
        if (!user.userContext.privilege.bestandsuitwisseling) {
          this.router.navigate(['/forbidden']);
          return false;
        }
        return true;
      }

      default: {
        return false;
      }
    }
  }
}
