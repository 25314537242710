export class ContentBlock {
    id: string;
    order: number;
    title: string;
    content: string;
    type: ContentBlockType;
    templates: DocumentTemplate[];
}

export class DocumentTemplate {
    id: string;
    fileName: string;
    fileSize: number;
}

export enum ContentBlockType {
    Informatie = 1,
    Waarschuwing = 2
}
