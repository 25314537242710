<div class="vl-col--1-1">
	<div class="vl-region">
		<div [ngClass]="{'loader-container': !inline}">
			<div class="loader">
				<div class="vl-loader" role="alert" aria-busy="true"></div>
				<p>
					Pagina is aan het laden
				</p>
			</div>
		</div>
	</div>
</div>
