import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import  config  from 'assets/configurations/config.json';
import { ActorType } from 'app/core/actor-type';


@Injectable()
export class ActorTypeService {

  constructor(private http: HttpClient) { }

  getActorTypes(): Observable<ActorType[]> {
    return this.http.get<ActorType[]>(config.portalApiEndpoint + `actortype`);
  }
}
