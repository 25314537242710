import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DirectoryHelper {
  private closeAll = new Subject<boolean>();
  private reloadAll = new Subject<boolean>();

  getTriggerCloseAll(): Subject<any> {
    return this.closeAll;
  }

  triggerCloseAll() {
    this.closeAll.next(true);
  }

  getTriggerReloadAllFolders(): Subject<any> {
    return this.reloadAll;
  }

  triggerReloadAllFolders() {
    this.reloadAll.next(true);
  }
}
