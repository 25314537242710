import { Injectable, ErrorHandler } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CorrelationTokenService } from 'app/core/correlation-token.service';
import { UserHelper } from 'app/user/user-shared/user-helper';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(public correlationTokenService: CorrelationTokenService,
              private userHelper: UserHelper,
              public errorHandler: ErrorHandler) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedRequest = request.clone();
    const token = this.correlationTokenService.getToken();
    const actorId = this.userHelper.getActorId();

    if (token !== undefined) {
      const headers = clonedRequest.headers.set('Nar-Correlation-Id', token);
      clonedRequest = clonedRequest.clone({headers});
    }

    if (actorId !== undefined) {
      const headers = clonedRequest.headers.set('Nar-Actor-Id', actorId);
      clonedRequest = clonedRequest.clone({headers});
    }

    return next.handle(clonedRequest);
  }
}
