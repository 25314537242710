<app-wu-alert *ngIf="errorOccurred" [message]="errorMessage"></app-wu-alert>

<div *ngIf="isAlert && !errorOccurred" id="{{contentBlock.id}}" class="vl-alert vl-alert--info vl-alert--large vl-typography" role="alert">
  <div class="vl-alert__content">
    <p class="vl-alert__title">{{contentBlock.title}}</p>
    <div class="vl-alert__message">
      <div [innerHTML]="contentBlock.content | trustHtml"></div>
      <a class="vl-document" *ngFor="let template of contentBlock.templates" (click)="downloadTemplate(template)">
        <div class="vl-document__type">
          <i class="vl-vi vl-vi-document" aria-hidden="true"></i>
          <span class="vl-document__type__text">{{template.fileName | slice:(template.fileName.lastIndexOf('.') + 1) | uppercase}}</span>
        </div>
        <div class="vl-document__content">
          <div class="vl-document__title vl-link" data-vl-clamp="2">
            {{template.fileName}}
          </div>
        </div>
      </a>
    </div>
  </div>
</div>

<div *ngIf="isInformation && !errorOccurred" id="{{contentBlock.id}}" class="vl-u-spacer--medium vl-typography">
  <h3 class="vl-title vl-title--h3 vl-title--has-border">
    {{contentBlock.title}}
  </h3>
  <div [innerHTML]="contentBlock.content | trustHtml"></div>
  <a class="vl-document no-text-decoration" *ngFor="let template of contentBlock.templates" (click)="downloadTemplate(template)">
    <div class="vl-document__type">
      <i class="vl-vi vl-vi-document" aria-hidden="true"></i>
      <span class="vl-document__type__text">{{template.fileName | slice:(template.fileName.lastIndexOf('.') + 1) | uppercase}}</span>
    </div>
    <div class="vl-document__content">
      <div class="vl-document__title vl-link" data-vl-clamp="2">
        {{template.fileName}}
      </div>
    </div>
  </a>
</div>
