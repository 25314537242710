<div class="collapse-block">
	<!-- TODO: add parameters to ngClass to show new style for x days last changed item -->
	<div class="collapse-block__top" [ngClass]="{'collapse-block--new': true}">
		<div class="vl-description-data" (click)="toggleDetails()">
			<div class="vl-grid">
				<div class="vl-col--1-5 vl-col--1-1--xs">
					<span class="vl-description-data__subdata vl-icon vl-vi state--{{showDetails ? 'open' : 'closed'}}"
						aria-hidden="true"></span>
				</div>
				<div class="vl-col--2-5 vl-col--1-1--xs collapse-block__title">
					<span class="vl-description-data__data">Map</span>
					<span class="vl-description-data__subdata">{{ directory.name }}</span>
				</div>
				<!-- TODO: add last changed data + remove hardcoded -->
				<div class="vl-col--2-5 vl-col--1-1--xs">
					<span class="vl-description-data__data">Laatste wijziging</span>
					<span class="vl-description-data__subdata">{{dateHelper.getCalendarDate(directory.timeLastModified)}}
						{{dateHelper.getTime(directory.timeLastModified)}}</span>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="showDetails" class="collapse-block__content">
		<app-skeleton *ngIf="!dataLoaded && !errorOccurred"></app-skeleton>
		<div class="vl-grid" *ngIf="errorOccurred">
			<div class="vl-col--1-1">
				<app-wu-general-alert *ngIf="errorOccurred" [correlationId]="correlationId"></app-wu-general-alert>
			</div>
		</div>
		<div class="vl-grid vl-u-spacer--small" *ngIf="dataLoaded && !errorOccurred">
			<div class="vl-col--1-1">
				<ul>
					<li *ngFor="let file of files">
						<div class="vl-document">
							<a class="vl-document__type no-text-decoration" (click)="downloadFile(file)">
								<i class="vl-vi vl-vi-document" aria-hidden="true"></i>
								<span class="vl-document__type__text">{{((file.name.split('.')).reverse())[0] | uppercase}}</span>
							</a>
							<a class="vl-document__content no-text-decoration" (click)="downloadFile(file)">
								<div class="vl-document__title vl-link" data-vl-clamp="2">
									{{file.name}}
								</div>
							</a>
							<a (click)="openConfirmDelete(file)">
								<i class="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-close-small"
									aria-hidden="true"></i>
							</a>
						</div>
					</li>
				</ul>
				<div *ngIf="validationErrors">
					<div *ngIf="validationErrors['hasWrongExtension']" class="vl-form__error">
						Dit soort bestand kan niet geupload worden.</div>
					<div *ngIf="validationErrors['containsIllegalCharacters']" class="vl-form__error">
						De bestandsnaam mag volgende tekens niet bevatten: {{user.parameters.illegalCharacters}}</div>
					<div *ngIf="validationErrors['stringIsTooLong']" class="vl-form__error">
						De bestandsnaam mag niet meer dan {{user.parameters.maxFilecharacters}} tekens lang zijn.</div>
					<div *ngIf="validationErrors['fileTooBig']" class="vl-form__error">
						Het bestand is te groot.</div>
				</div>
				<div class="vl-upload" [ngClass]="{'vl-upload--disabled': this.user.userContext.readOnly}">
					<div class="vl-upload__element">
						<input class="vl-upload__element__input" type="file" name="{{directory.name}}-file"
							   id="{{directory.name}}-file" (change)="onFileSelect($event)"
							   [disabled]="this.user.userContext.readOnly">
						<label class="vl-upload__element__label" for="{{directory.name}}-file">
							<i class="vl-vi vl-vi-paperclip" aria-hidden="true"></i>
							<span>Bestand toevoegen</span>
						</label>
					</div>
				</div>
			</div>
		</div>

		<div class="vl-grid vl-u-spacer--small" [formGroup]="updateDirectoryNameForm">
			<!-- Name -->
			<label for="directory-name" class="vl-col--1-4">
				<strong>
					Map
				</strong>
			</label>
			<div *ngIf="!isUploading" class="vl-col--3-4 vl-col--1-1--s">
				<input
					[ngClass]="{'vl-input-field--error': (formHelper.isNotValidField(updateDirectoryNameForm.controls['name']))}"
					class="vl-input-field vl-input-field--block" type="text" id="directory-name" name="directory-name"
					formControlName="name" />
				<div *ngIf="formHelper.isNotValidField(updateDirectoryNameForm.get('name'))">
					<p *ngIf="updateDirectoryNameForm.get('name').hasError('required')" class="vl-form__error">
						Gelieve een titel in te geven.</p>
					<p *ngIf="updateDirectoryNameForm.get('name').hasError('maxlength')" class="vl-form__error">
						Gelieve een titel in te geven met niet meer dan {{user.parameters.maxFilecharacters}} karakters.</p>
					<p *ngIf="updateDirectoryNameForm.get('name').hasError('containsIllegalCharacters')" class="vl-form__error">
						De titel mag volgende tekens niet bevatten: {{user.parameters.illegalCharacters}}</p>
						<p *ngIf="updateDirectoryNameForm.get('name').hasError('nameIsNotUnique')" class="vl-form__error">
							Er bestaat al een map met deze naam</p>	
				</div>
			</div>
			<div *ngIf="isUploading" class="vl-col--3-4 vl-col--1-1--s">
				{{directory.name}}
			</div>
			<!-- end Name -->
		</div>
		<div class="vl-action-group">
			<button 
					[ngClass]="{'vl-button--loading': isUploading, 'vl-button--disabled': this.user.userContext.readOnly || updateDirectoryNameForm.get('name').hasError('nameIsNotUnique')}" 
					[disabled]="this.user.userContext.readOnly || updateDirectoryNameForm.get('name').hasError('nameIsNotUnique')"
				class="vl-button vl-u-spacer--small" (click)="updateDirectoryName()">
				Opslaan
			</button>
			<button [ngClass]="{'vl-button--loading': downloadingFolder}" [disabled]="downloadingFolder"
				class="vl-button vl-u-spacer--small" (click)="downloadFolder(directory)">
				Download .zip
			</button>
		</div>
	</div>
</div>

<!-- Modal -->
<app-modal id="{{directory.name}}" class="vl-modal-dialog" data-vl-modal data-vl-modal-closable tabindex="-1"
	role="dialog" aria-modal="true" aria-hidden="true" aria-labelledby="modal-toggle-1-title"
	aria-describedby="modal-toggle-1-description">
	<button type="button" class="vl-modal-dialog__close" data-vl-modal-close (click)="cancelConfirmDelete()">
		<i class="vl-modal-dialog__close__icon vl-vi vl-vi-cross" aria-hidden="true"></i>
		<span class="vl-u-visually-hidden">Venster sluiten</span>
	</button>
	<h2 class="vl-modal-dialog__title" id="modal-toggle-1-title">
		Bestand verwijderen?
	</h2>
	<div class="vl-modal-dialog__content vl-u-spacer--medium" id="modal-toggle-1-description">
		Bent u zeker dat u '{{toDeleteFilename}}' wilt verwijderen?
	</div>
	<div *ngIf="files.length === 1" class="vl-modal-dialog__content vl-u-spacer--medium" id="modal-toggle-1-description">
		Aangezien dit het laatste bestand in deze map is, zal de map zelf ook verwijderd worden.
	</div>
	<div class="vl-action-group">
		<button *ngIf="files.length > 1" class="vl-button" (click)="deleteFile()">
			Verwijderen</button>
		<button *ngIf="files.length === 1" class="vl-button" (click)="deleteFolder()">
			Verwijderen</button>
		<a href="javascript:void(0);" class="vl-link" data-vl-modal-close (click)="cancelConfirmDelete()">
			Niet verwijderen
		</a>
	</div>
</app-modal>
<!-- END modal -->