<div class="vl-col--1-1 vl-u-spacer--medium">
	<div class="skeleton skeleton--files">
		<div class="skeleton__header">
			<div class="skeleton__title shine"></div>
		</div>
		<div class="skeleton__content">
			<div class="skeleton__file">
				<span class="shine"></span>
				<p class="shine"></p>
			</div>
			<div class="skeleton__upload">
				<span class="shine"></span>
			</div>
			<div class="skeleton__form">
				<span class="shine"></span>
				<span class="shine"></span>
			</div>

			<div class="skeleton__form">
				<span class="shine"></span>
				<span class="shine"></span>
			</div>

		</div>
	</div>
</div>



