import { Component } from '@angular/core';
import { AuthService } from 'app/core/auth.service';

@Component({
  template: `
    <div
      class="vl-page vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center"
    >
      <div
        id="main"
        itemprop="mainContentOfPage"
        role="main"
        tabindex="-1"
        class="vl-main-content"
      >
        <div class="vl-region">
          <div class="vl-layout">
            <div
              class="vl-grid vl-grid--is-stacked vl-grid--align-center vl-grid--v-center"
            >
              <!-- content -->
              <div class="vl-col--1-2 vl-u-hidden vl-u-visible--s">
                <div
                  class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center"
                >
                  <img src="./assets/img/status/domain-reserved.svg" />
                </div>
              </div>

              <div class="vl-col--1-2 vl-col--8-12--s vl-col--1-1--xs">
                <div class="vl-grid vl-grid--is-stacked">
                  <div class="vl-col--1-1">
                    <h1 class="vl-title vl-title--h2">Niet geautoriseerd</h1>
                    <div class="vl-typography">
                      <p>
                        Je bent niet toegelaten deze pagina te zien. Meld je
                        a.u.b. aan.
                      </p>
                    </div>
                  </div>

                  <div class="vl-col--1-1">
                    <div class="vl-action-group">
                      <a href="/" class="vl-button"
                        >Terug naar de startpagina</a>
                      <button (click)="signIn()" class="vl-button">Inloggen</button>
                    </div>
                  </div>

                  <div class="vl-col--1-1">
                    <p>
                      Blijft het probleem bestaan,
                      <!-- TODO: link contact -->
                      <a
                        href="#CTA naar formulier om een foutieve link te melden"
                        >contacteer ons</a
                      >.
                    </p>
                  </div>
                </div>
              </div>

              <div class="vl-col--1-2 vl-u-hidden--s">
                <div
                  class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center"
                >
                  <img src="./assets/img/status/domain-reserved.svg" />
                </div>
              </div>
              <!-- end content -->
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class UnauthorizedComponent {
  constructor(private authService: AuthService) {}

  signIn() {
    this.authService.signIn();
  }
}
