import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-wu-general-alert',
  templateUrl: './wu-general-alert.component.html'
})
export class WuGeneralAlertComponent implements OnInit {
  @Input() correlationId: string;
  errorMessage: string;

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.errorMessage = `Er is iets misgelopen tijdens het ophalen van gegevens voor deze pagina. <br /> Neem contact met ons op via <a href="mailto:toezicht.wonen@vlaanderen.be?subject=Fout bij het laden van pagina '${this.correlationId}'">toezicht.wonen@vlaanderen.be</a> als dit probleem zich blijft voordoen en vermeld dan volgende referentie: ${this.correlationId}.`;
  }
}
