import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/core/auth.service';
import { User } from 'app/core/user';
import { UserService } from 'app/user/user-shared/user-service';
import { BrowserStorageHelper } from 'app/shared/helpers/browser-storage.helper';
import { CorrelationTokenService } from 'app/core/correlation-token.service';
import { LoggingService } from 'app/core/logging.service';


@Component({
  template: `
  <app-wu-alert *ngIf="errorOccurred" [message]="errorMessage"></app-wu-alert>
 	<div *ngIf="!errorOccurred" class="vl-page vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center">
    <div id="main" itemprop="mainContentOfPage" role="main" tabindex="-1" class="vl-main-content">
      <div class="vl-region">
        <div class="vl-layout">
          <div class="vl-grid vl-grid--is-stacked vl-grid--align-center vl-grid--v-center">
            <!-- content -->
            <div class="vl-col--1-2 vl-u-hidden vl-u-visible--s">
			  <div class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center">
			  	<img src="./assets/img/status/connection-issue.svg">
              </div>
            </div>

            <div class="vl-col--1-2 vl-col--8-12--s vl-col--1-1--xs">
              <div class="vl-grid vl-grid--is-stacked">
                <div class="vl-col--1-1">
                  <h1 class="vl-title vl-title--h2">Aan het aanmelden</h1>
                  <div class="vl-typography">
                    <p>Even wachten a.u.b., we zijn je gegevens aan het ophalen... </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="vl-col--1-2 vl-u-hidden--s">
              <div class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center">
                <img src="./assets/img/status/connection-issue.svg">
              </div>
            </div>
            <!-- end content -->
          </div>
        </div>
      </div>
    </div>
  </div>
  `
})
export class CallbackComponent implements OnInit {
  errorOccurred = false;
  errorMessage: string;
  correlationId: string;
  nrOfRetries = 0;
  maxNrOfRetries = 1;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private browserStorageHelper: BrowserStorageHelper,
    private correlationTokenService: CorrelationTokenService,
    private loggingService: LoggingService) {
  }

  ngOnInit(): void {
    try {
      this.activatedRoute.queryParams.subscribe(params => {
        const code = params['code'];
        const stateFromParams = params['state'];

        this.validateState(stateFromParams);
        this.exchangeCodeForToken(code);
      });
    } catch (error) {
      // tslint:disable-next-line:max-line-length
      this.errorMessage = `Er is iets misgelopen tijdens het aanloggen.<br /> Neem contact met ons op via <a href="mailto:toezicht.wonen@vlaanderen.be?subject=Fout tijdens inloggen '${error.message}'">toezicht.wonen@vlaanderen.be</a> als dit probleem zich blijft voordoen en vermeld dan volgende referentie: ${error.message}.`;
      this.errorOccurred = true;
      throw error;
    }
  }
  private validateState(stateFromParams: string): void {
    try {
      const savedState = this.browserStorageHelper.getState();
      if (stateFromParams !== savedState) {
        throw new Error('Saved state does not match returned state from idp');
      } else {
        this.browserStorageHelper.removeState();
      }
    } catch (error) {
      // tslint:disable-next-line:max-line-length
      this.errorMessage = `Er is iets misgelopen tijdens het aanloggen.<br /> Neem contact met ons op via <a href="mailto:toezicht.wonen@vlaanderen.be?subject=Fout tijdens inloggen '${error.message}'">toezicht.wonen@vlaanderen.be</a> als dit probleem zich blijft voordoen en vermeld dan volgende referentie: ${error.message}.`;
      this.errorOccurred = true;
      throw error;
    }
  }

  private exchangeCodeForToken(code: string) {
    try {
      if (code) {
        this.correlationId = this.correlationTokenService.setToken();
        this.authService.getAccessToken(code).subscribe({
          next: idToken => {
            this.authService.setToken(idToken);
            this.authService.scheduleNewAccessTokenFetch();

            this.correlationId = this.correlationTokenService.setToken();
            const redirected = this.redirectToActivationIfRedirectUrlPresent();
            this.redirectToUserSelection(redirected);
          }, error: error => {
            console.log(error);
            // tslint:disable-next-line:max-line-length
            this.errorMessage = `Er is iets misgelopen tijdens het aanloggen.<br /> Neem contact met ons op via <a href="mailto:toezicht.wonen@vlaanderen.be?subject=Fout tijdens inloggen '${this.correlationId}'">toezicht.wonen@vlaanderen.be</a> als dit probleem zich blijft voordoen en vermeld dan volgende referentie: ${this.correlationId}.`;
            this.errorOccurred = true;
          }
        });
      }
    } catch (error) {
      throw error;
    }
  }

  private redirectToActivationIfRedirectUrlPresent(): boolean {
    console.log('Redirecting to activation because redirectUrlPresent');
    const redirectUrl = this.browserStorageHelper.getRedirectUrl();
    const redirectParams = this.browserStorageHelper.getRedirectQueryParams();
    if (redirectUrl === '/user/activation') {
      this.browserStorageHelper.removeRedirectUrl();
      this.browserStorageHelper.removeRedirectQueryParams();
      this.router.navigate(['/user/activation'], {queryParams: JSON.parse(redirectParams)});
      return true;
    }
    return false;
  }

  private redirectToUserSelection(alreadyRedirected: boolean): void {
    if (!alreadyRedirected) {
      this.router.navigate(['/user/selection']);
    }
  }
}
