import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Injectable()
export class CorrelationTokenService {
    private token: string;

    getToken(): string {
        return this.token;
    }

    setToken(): string {
        this.token = uuid();
        return this.token;
    }
}
