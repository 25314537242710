import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { LoggingService } from 'app/core/logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error) {
    const loggingService = this.injector.get(LoggingService);
    loggingService.log(error);
    throw error;
  }
}
