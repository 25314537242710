import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import FileSaver from 'file-saver';
import moment from 'moment';

import { FileExchangeService } from 'app/shared/directory/file-exchange.service';
import { Directory } from 'app/shared/directory/directory';
import { SharepointFile } from 'app/shared/directory/sharepoint-file';
import { Subscription } from 'rxjs';
import { DirectoryHelper } from 'app/shared/directory/directory-helper';
import { CustomValidators } from 'app/core/custom-validators';
import { User } from 'app/core/user';
import { BrowserStorageHelper } from 'app/shared/helpers/browser-storage.helper';
import { FormHelper } from 'app/core/form-helper';
import { CorrelationTokenService } from 'app/core/correlation-token.service';
import { ModalService } from 'app/shared/modal/modal.service';
import { DateHelper } from 'app/core/date-helper';
import { ValidationContext } from 'app/core/validation-context';


@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html'
})
export class DirectoryComponent implements OnInit, OnDestroy {
  @Input() directory: Directory;
  @Input() directories: Directory[];
  @Input() parentFolderUrl: string;
  showDetails = false;
  dataLoaded = false;
  downloading = false;
  errorOccurred = false;
  files: SharepointFile[] = [];
  closeAllSubscription: Subscription;
  updateDirectoryNameForm: FormGroup;
  user: User;
  correlationId: string;
  file: File;
  validationErrors: {} = {};
  hasValidationErrors = false;
  toDeleteFilename: string;
  toDeleteFileUrl: string;
  isUploading = false;
  downloadingFolder = false;

  constructor(private fileExchangeService: FileExchangeService,
    private directoryHelper: DirectoryHelper,
    private formBuilder: FormBuilder,
    private customValidators: CustomValidators,
    private browserStorageHelper: BrowserStorageHelper,
    private formHelper: FormHelper,
    private correlationTokenService: CorrelationTokenService,
    private modalService: ModalService,
    public dateHelper: DateHelper) { }

  ngOnInit() {
    this.user = this.browserStorageHelper.getUser();
    this.directories = this.filterThisFromDirectoryList(this.directories);

    this.closeAllSubscription = this.directoryHelper.getTriggerCloseAll().subscribe(result => {
      if (result) {
        this.showDetails = false;
      }
    });
  }

  filterThisFromDirectoryList(directories: Directory[]): any {
    return directories.filter(directory => directory.name !== this.directory.name);
  }

  ngOnDestroy() {
    this.closeAllSubscription.unsubscribe();
  }

  toggleDetails() {
    if (this.showDetails === false) {
      this.showDetails = true;
      this.user = this.browserStorageHelper.getUser();
      this.initForm();
      this.correlationTokenService.setToken();

      const folderUrl = encodeURI(this.directory.folderUrl);
      this.fileExchangeService.getFolderDetails(folderUrl, this.directory.baseUrl).subscribe(details => {
        this.directory = details;
        this.getFiles();
      });
    } else {
      this.showDetails = false;
    }
  }

  getFiles() {
    const folderUrl = encodeURI(this.directory.folderUrl);

    this.correlationTokenService.setToken();
    this.fileExchangeService.getFileList(folderUrl, this.directory.baseUrl).subscribe({
      next: (files: SharepointFile[]) => {
        this.files = files;
        this.dataLoaded = true;
      },
      error: _ => {
        this.errorOccurred = true;
      }
    });
  }

  public downloadFile(file: SharepointFile) {
    if (!this.downloading) {
      this.downloading = true;

      this.correlationTokenService.setToken();
      this.fileExchangeService.downloadFile(file.fileUrl, this.directory.baseUrl).subscribe({
        next: blob => {
          FileSaver.saveAs(blob, file.name);

          this.downloading = false;
        },
        error: () => {
          this.errorOccurred = true;
        }
      });
    }
  }

  public downloadFolder(directory: Directory) {
    if (!this.downloading) {
      this.downloading = true;
      this.downloadingFolder = true;

      this.correlationTokenService.setToken();
      this.fileExchangeService.downloadFolder(directory.folderUrl, directory.baseUrl).subscribe({
        next: blob => {
          FileSaver.saveAs(blob, `${directory.name}.zip`);
          this.downloading = false;
          this.downloadingFolder = false;
        },
        error: () => {
          this.errorOccurred = true;
        }
      });
    }
  }

  public updateDirectoryName(): void {
    if (!this.updateDirectoryNameForm.valid) {
      this.formHelper.showErrors(this.updateDirectoryNameForm);
      return;
    }

    this.isUploading = true;
    this.directory.name = this.updateDirectoryNameForm.get('name').value;

    this.correlationId = this.correlationTokenService.setToken();
    this.fileExchangeService.updateDirectory(this.directory).subscribe({
      next: _ => {
        this.directoryHelper.triggerReloadAllFolders();
      },
      error: () => {
        this.errorOccurred = true;
        this.isUploading = false;
      }
    });
  }

  public onFileSelect(event: any) {
    this.file = event.target.files[0];

    this.validationErrors = [{}];
    this.hasValidationErrors = false;
    this.validationErrors = this.customValidators.validateFile(this.file, this.user, ValidationContext.FileExchange);

    if (this.validationErrors === null) {
      this.uploadFile(this.file);
    }
  }

  public uploadFile(file: File): void {
    this.dataLoaded = false;

    this.correlationId = this.correlationTokenService.setToken();
    this.fileExchangeService.uploadFile(`${this.parentFolderUrl}/${this.directory.name}`, this.directory.baseUrl, file)
      .subscribe({
        next: _ => {
          this.directory.timeLastModified = moment();
          this.getFiles();
        },
        error: () => {
          this.errorOccurred = true;
        }
      });
  }

  public openConfirmDelete(file: SharepointFile): void {
    this.toDeleteFilename = file.name;
    this.toDeleteFileUrl = encodeURI(file.fileUrl);

    this.openModal(this.directory.name);
  }

  public deleteFile() {
    this.dataLoaded = false;
    this.closeModal(this.directory.name);

    this.correlationTokenService.setToken();
    this.fileExchangeService.deleteFile(this.toDeleteFileUrl, this.directory.baseUrl).subscribe(_ => {
      this.directory.timeLastModified = moment();
      this.getFiles();
    });
  }

  public deleteFolder() {
    this.dataLoaded = false;
    this.closeModal(this.directory.name);

    this.correlationTokenService.setToken();
    this.fileExchangeService.deleteFile(this.toDeleteFileUrl, this.directory.baseUrl).subscribe(_ => {
      const folderUrl = encodeURI(this.directory.folderUrl);

      this.correlationTokenService.setToken();
      this.fileExchangeService.deleteFolder(folderUrl, this.directory.baseUrl).subscribe(_ => {
        this.directoryHelper.triggerReloadAllFolders();
      });
    });
  }

  public cancelConfirmDelete(): void {
    this.closeModal(this.directory.name);

    this.toDeleteFilename = '';
    this.toDeleteFileUrl = '';
  }


  private initForm(): void {
    this.updateDirectoryNameForm = this.formBuilder.group({
      name: [ this.directory.name,
              [Validators.required,
                Validators.maxLength(this.user.parameters.maxFilecharacters),
                this.customValidators.containsIllegalCharactersValidator(this.user.parameters.illegalCharacters),
                this.customValidators.nameIsNotUniqueValidator(this.directories)]]
    });
  }

  private openModal(id: string) {
    this.modalService.open(id);
  }

  private closeModal(id: string) {
    this.modalService.close(id);
  }
}
