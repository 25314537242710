import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

import { BrowserStorageHelper } from 'app/shared/helpers/browser-storage.helper';


@Injectable()
export class ComponentGuard implements CanActivate {

  constructor(private router: Router,
    private browserStorageHelper: BrowserStorageHelper) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.browserStorageHelper.getUser();
    const childRoute = route.url[1].path;

    switch (childRoute) {
      case 'bestandsuitwisseling': {
        if (!user.userContext.privilege.globaalOnderzoekBestandsuitwisseling) {
          this.router.navigate(['/forbidden']);
          return false;
        }
        return true;
      }

      case 'vaststellingen': {
        if (!user.userContext.privilege.globaalOnderzoekVaststellingen) {
          this.router.navigate(['/forbidden']);
          return false;
        }
        return true;
      }

      default: {
        return false;
      }
    }
  }
}
