import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { WuDatepickerDirective } from 'app/shared/webuniversum/wu-datepicker.directive';
import { WuPagerComponent } from 'app/shared/webuniversum/pager/wu-pager.component';
import { WuMultiselectDirective } from 'app/shared/webuniversum/wu-multiselect.directive';
import { WuTooltipDirective } from 'app/shared/webuniversum/wu-tooltip.directive';
import { WuAutocompleteDirective } from 'app/shared/webuniversum/wu-autocomplete.directive';
import { LoaderComponent } from 'app/shared/loader/loader.component';
import { ContentBlockComponent } from 'app/shared/content-block/content-block.component';
import { ContentBlockService } from 'app/shared/content-block/content-block-service';
import { TrustHtmlPipe } from 'app/shared/pipes/trust-html.pipe';
import { SideNavComponent } from 'app/shared/side-nav/side-nav.component';
import { BrowserStorageHelper } from 'app/shared/helpers/browser-storage.helper';
import { ContentBlockHelper } from 'app/shared/helpers/content-block.helper';
import { ModalComponent } from './modal/modal.component';
import { ModalService } from './modal/modal.service';
import { WuAlertComponent } from './webuniversum/wu-alert/wu-alert.component';
import { WuGeneralAlertComponent } from 'app/shared/webuniversum/wu-general-alert/wu-general-alert.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { DirectoryComponent } from './directory/directory.component';
import { DirectoryHelper } from 'app/shared/directory/directory-helper';
import { WuSidenavDirective } from './webuniversum/wu-sidenav.directive';
import { WuStickyDirective } from './webuniversum/wu-sticky.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [
    WuDatepickerDirective,
    WuMultiselectDirective,
    WuPagerComponent,
    WuTooltipDirective,
    WuAutocompleteDirective,
    LoaderComponent,
    ContentBlockComponent,
    SideNavComponent,
    TrustHtmlPipe,
    ModalComponent,
    WuAlertComponent,
    WuGeneralAlertComponent,
    SkeletonComponent,
    ScrollToTopComponent,
    DirectoryComponent,
    WuSidenavDirective,
    WuStickyDirective
  ],
  exports: [
    CommonModule,
    WuDatepickerDirective,
    WuMultiselectDirective,
    WuPagerComponent,
    WuTooltipDirective,
    WuAutocompleteDirective,
    LoaderComponent,
    ContentBlockComponent,
    SideNavComponent,
    TrustHtmlPipe,
    ModalComponent,
    WuAlertComponent,
    WuGeneralAlertComponent,
    SkeletonComponent,
    ScrollToTopComponent,
    DirectoryComponent,
    WuSidenavDirective,
    WuStickyDirective
  ],
  providers: [
    ContentBlockService,
    BrowserStorageHelper,
    ContentBlockHelper,
    ModalService,
    DirectoryHelper
  ]
})
export class SharedModule { }
