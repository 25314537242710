import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import  config  from 'assets/configurations/config.json';
import { UserActivation } from 'app/user/user-shared/user-activation';
import { UserActivationInitialization } from 'app/user/user-shared/user-activation-initialization';
import { User } from 'app/core/user';
import { MultiContextUser } from 'app/user/user-shared/multi-context-user';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: Subject<User> = new Subject();

    constructor(private http: HttpClient) { }

    getMultiContextUser(): Observable<MultiContextUser> {
        return this.http.get<MultiContextUser>(config.portalApiEndpoint + `user/multicontextuser`);
    }

    getUser(): Observable<User> {
        return this.http.get<User>(config.portalApiEndpoint + `user`);
    }

    deleteFromCache(): Observable<any> {
        return this.http.delete(config.portalApiEndpoint + `user/cache`);
    }

    initializeActivation(userActivation: UserActivationInitialization): Observable<UserActivationInitialization> {
        return this.http.post<UserActivationInitialization>(config.portalApiEndpoint + `user/activation`, userActivation);
    }

    activate(userActivation: UserActivation): Observable<UserActivation> {
        return this.http.put<UserActivation>(config.portalApiEndpoint + `user/activation`, userActivation);
    }
}
