import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray, AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable()
export class FormHelper {
  constructor() { }

  convertToGroup(control: AbstractControl) {
    return <FormGroup>control;
  }

  convertToArray(control: AbstractControl) {
    return <FormArray>control;
  }

  setControlValidators(control: AbstractControl, newValidator: ValidatorFn | ValidatorFn[]): void {
    control.setValidators(newValidator);
    control.updateValueAndValidity();
  }

  disableValidationForGroup(group: FormGroup): void {
    Object.keys(group.controls)
      .forEach(key => {
        const control = <FormGroup>group.controls[key];
        if (!control.controls || Array.isArray(control.controls)) {
          // control or an array
          this.setControlValidators(control, null);
        } else {
          this.disableValidationForGroup(control);
        }
      });
  }

  formIsInvalidAndEditableAndTouched(form: FormGroup): boolean {
    return !form.valid && form.touched;
  }

  formIsInvalidAndTouched(form: FormGroup): boolean {
    return !form.valid && form.touched;
  }

  formHasErrorAndControlTouched(form: FormGroup, errorName: string, control: AbstractControl): boolean {
    return form.hasError(errorName) && control.touched;
  }

  isNotValidField(control: AbstractControl): boolean {
    return !control.valid && control.touched && !control.disabled;
  }

  isNotValidAndNotDisabled(control: AbstractControl): boolean {
    return !control.valid && !control.disabled;
  }

  disableControlsForGroup(group: FormGroup): void {
    Object.keys(group.controls)
      .forEach(key => {
        const control = <FormGroup>group.controls[key];
        if (!control.controls || Array.isArray(control.controls)) {
          // control or an array
          control.disable();
        } else {
          this.disableControlsForGroup(control);
        }
      });
  }

  enableValidationForGroup(group: FormGroup, model: any): void {
    Object.keys(group.controls)
      .forEach(key => {
        const field = model[key];
        if (field) {
          this.setControlValidators(group.controls[key], Array.isArray(field) ? field[1] : field.validator);
        }
      });
  }

  range(start: number, end: number): number[] {
    return Array(end - start + 1).fill(0).map((_, idx) => start + idx);
  }

  cloneDeep<T>(obj: T): T {
    // return value is input is not an Object or Array.
    if (typeof (obj) !== 'object' || obj === null) {
      return obj;
    }

    const clone = Array.isArray(obj)
      ? obj.slice()  // unlink Array reference.
      : Object.assign({}, obj); // Unlink Object reference.

    const keys = Object.keys(clone);

    for (let i = 0; i < keys.length; i++) {
      clone[keys[i]] = this.cloneDeep(clone[keys[i]]); // recursively unlink reference to nested objects.
    }

    return <T>clone; // return unlinked clone.
  }

  showErrors(group: FormGroup): void {
    Object.keys(group.controls)
      .forEach(key => {
        const control = group.controls[key];
        if ((<FormGroup>control).controls) {
          this.showErrors(<FormGroup>control);
        }
        control.markAsTouched();
      });
  }

  stretchArray(array: FormArray, index: number) {
    const length = array.length;
    if (length <= index) {
      for (let i = length; i <= index; i++) {
        array.push(new FormControl());
      }
    }
  }
}
