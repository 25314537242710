import { Injectable } from '@angular/core';

import { ContentBlock, ContentBlockType } from 'app/shared/content-block/content-block';
import { ContentBlockTitlelistItem } from 'app/shared/content-block/content-block-titlelist-item';

@Injectable()
export class ContentBlockHelper {

    constructor() { }

    generateTitleList(
        hardcodedTitleList: ContentBlockTitlelistItem[],
        topContentBlocks: ContentBlock[],
        bottomContentBlocks: ContentBlock[] = []): ContentBlockTitlelistItem[] {

        const titleList: ContentBlockTitlelistItem[] = [];

        topContentBlocks.forEach((block: ContentBlock) => {
            if (block.type === ContentBlockType.Informatie) {
                titleList.push({ id: block.id, title: block.title });
            }
        });

        titleList.push(...hardcodedTitleList);

        bottomContentBlocks.forEach((block: ContentBlock) => {
            if (block.type === ContentBlockType.Informatie) {
                titleList.push({ id: block.id, title: block.title });
            }
        });

        return titleList;
    }
}
