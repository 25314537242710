import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from 'app/core/auth.service';
import { BrowserStorageHelper } from 'app/shared/helpers/browser-storage.helper';
import { User } from 'app/core/user';
import { UserHelper } from 'app/user/user-shared/user-helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'toezicht-portal-web';
  isAuthorizedSubscription: Subscription;
  hash: string;
  correlationId: string;
  errorOccurred = false;
  user: User;

  constructor(
    private authService: AuthService,
    private router: Router,
    private browserStorageHelper: BrowserStorageHelper,
    private userHelper: UserHelper) { }

  ngOnInit() {
    if (this.authService.isSignedIn()) {
      this.initUser();
    }
    this.authService.userChanged().subscribe((signedIn: boolean) => {
      if (signedIn) {
        this.user = this.browserStorageHelper.getUser();
      }
    });
  }

  private initUser(): void {
    this.authService.scheduleNewAccessTokenFetch();
    this.user = this.browserStorageHelper.getUser();
    if (this.user) {
      this.userHelper.setActorId(this.user.userContext.actorId);
    }
  }

  isSignedIn() {
    return this.authService.isSignedIn();
  }

  signIn() {
    this.authService.signIn();
  }

  signOut() {
    this.authService.signOut();
  }

  userDisplayName() {
    return this.authService.getUserDisplayName();
  }

  accountName() {
    return this.authService.getAccountName();
  }
}
