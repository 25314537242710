import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class DateHelper {
  readonly dateFormat = 'DD/MM/YYYY';
  readonly hourFormat = 'HH:mm';

  public convertDate(date) {
      return date ? moment(date) : null;
  }

  public getCalendarDate(date) {
      return date ? moment(date).format(this.dateFormat) : null;
  }

  public getTime(date) {
      return date ? moment(date).format(this.hourFormat) : null;
  }

  public moreThanNumberOfMonthsAgo(date, numberOfMonths: number) {
      if (moment(new Date()).diff(moment(date), 'month') >= numberOfMonths) {
          return true;
      }
      return false;
  }

  public getLeadingZeroHour(hour: number): string {
    if (hour < 10) {
        return String('0' + hour);
      }

      return String(hour);
  }
}
