import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UserHelper {
  private actorId: string;
  private employeeSelectedActorTypeId: string;
  private employeeSelectedActorId: string;

  setActorId(actorId: string): void {
    this.actorId = actorId;
  }

  getActorId(): string {
    return this.actorId;
  }

  setEmployeeSelectedActorTypeId(actorTypeId: string): void {
    this.employeeSelectedActorTypeId = actorTypeId;
  }

  getEmployeeSelectedActorTypeId(): string {
    return this.employeeSelectedActorTypeId;
  }

  setEmployeeSelectedActorId(actorId: string): void {
    this.employeeSelectedActorId = actorId;
  }

  getEmployeeSelectedActorId(): string {
    return this.employeeSelectedActorId;
  }
}
