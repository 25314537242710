import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import  config  from 'assets/configurations/config.json';
import { Log } from 'app/core/log';
import { AuthService } from 'app/core/auth.service';


@Injectable()
export class LoggingService {

  constructor(private http: HttpClient,
    private authService: AuthService) {
  }

  log(message) {
    console.log('Logging from loggingService:', message);

    if (this.authService.isSignedIn()) {
      const log: Log = {
        message: String(message)
      };
      return this.http.post(config.portalApiEndpoint + 'log', log).subscribe();
    }
  }
}
