<div class="vl-region">
	<div class="vl-layout">
		<div class="vl-grid vl-grid--is-stacked vl-grid--align-space-between vl-grid--v-bottom vl-u-spacer--medium">
			<div class="vl-col--12-12">
				<div class="vl-alert vl-alert--error" role="alert">
					<div class="vl-alert__icon">
						<i class="vl-vi vl-vi-warning" aria-hidden="true"></i>
					</div>
					<div class="vl-alert__content">
						<p class="vl-alert__title">Opgelet!</p>
						<div class="vl-alert__message">
							<p class="vl-u-spacer--small" [innerHtml]="message">
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
