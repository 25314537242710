import { Directive, ElementRef, AfterViewInit } from '@angular/core';

declare var vl: any;

@Directive({
  selector: '[appWuTooltip]',
})
export class WuTooltipDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    vl.tooltip.dress(this.elementRef.nativeElement);
  }
}
