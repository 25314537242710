<div class="vl-region">
	<div class="vl-layout">
		<h1 class="vl-title vl-title--h1 vl-u-spacer--none">Webuniversum testpage</h1>

		<div class="vl-sticky-bar" data-vl-sticky-bar data-vl-sticky-bar-trigger="sticky-1" appWuStickyDirective>
			<h3 class="vl-sticky-bar__item vl-sticky-bar__title">Test sticky</h3>
			<div class="vl-sticky-bar__item">
				<a href="#sticky-1" class="vl-button" tabindex="-1">Actieknop</a> </div>
		</div>
		<div class="vl-sticky-bar" data-vl-sticky-bar data-vl-sticky-bar-trigger="sticky-2" appWuStickyDirective>
			<h3 class="vl-sticky-bar__item vl-sticky-bar__title">Test sticky 2</h3>
			<div class="vl-sticky-bar__item">
				<a href="#sticky-2" class="vl-button" tabindex="-1">Actieknop</a> </div>
		</div>

		<div id="sticky-1">
			<div class="vl-title-wrapper vl-title-wrapper--cta  js-vl-sticky-bar-base" data-vl-sticky-bar-id="sticky-1">
				<h3 class="vl-title vl-title--h3 vl-title-wrapper__title vl-title-wrapper--cta__title">Test sticky</h3>
				<a href="#sticky-1" class="vl-button vl-cta-title__cta">Actieknop</a>
			</div>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
		</div>
		<div id="sticky-2">
			<div class="vl-title-wrapper vl-title-wrapper--cta  js-vl-sticky-bar-base" data-vl-sticky-bar-id="sticky-2">
				<h3 class="vl-title vl-title--h3 vl-title-wrapper__title vl-title-wrapper--cta__title">Test sticky 2</h3>
				<a href="#sticky-2" class="vl-button vl-cta-title__cta">Actieknop</a>
			</div>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis libero iure officia esse eius veniam laboriosam
				deleniti excepturi nam hic, sunt mollitia minus error cumque culpa. Odio commodi itaque sequi!</p>
		</div>
	</div>
</div>
