import { Directive, AfterViewInit, ElementRef } from '@angular/core';

declare var vl: any;

@Directive({
  selector: '[appWuSidenav]'
})
export class WuSidenavDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    vl.sideNavigation.dress(this.elementRef.nativeElement);
    vl.scrollspy.dress(this.elementRef.nativeElement);
  }

}
