import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import  config  from 'assets/configurations/config.json';
import { CodeTableItem } from 'app/core/code-table-item';


@Injectable()
export class CodeTableService {

  constructor(private http: HttpClient) { }

  getBoardMeetingTypes(): Observable<CodeTableItem[]> {
    return this.http.get<CodeTableItem[]>(config.portalApiEndpoint + `boardmeetingtype`);
  }

  getPublicProcurementClasses(onlyActive: boolean): Observable<CodeTableItem[]> {
    const params = new HttpParams().set('onlyActive', JSON.stringify(onlyActive));
    return this.http.get<CodeTableItem[]>(config.portalApiEndpoint + `publicprocurementclass`, { params });
  }

  getPublicProcurementSubClasses(onlyActive: boolean): Observable<CodeTableItem[]> {
    const params = new HttpParams().set('onlyActive', JSON.stringify(onlyActive));
    return this.http.get<CodeTableItem[]>(config.portalApiEndpoint + `publicprocurementsubclass`, { params });
  }

  getPublicProcurementAllocationMethods(onlyActive: boolean): Observable<CodeTableItem[]> {
    const params = new HttpParams().set('onlyActive', JSON.stringify(onlyActive));
    return this.http.get<CodeTableItem[]>(config.portalApiEndpoint + `publicprocurementallocationmethod`, { params });
  }
}
