import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-wu-pager',
  template: `
  <div class="vl-pager vl-pager--align-center vl-u-spacer--medium">
    <ul class="vl-pager__list">
      <li class="vl-pager__element">
        <span class="vl-u-visually-hidden">Rij</span>
        <strong>{{pageSize * page - pageSize +1}}-{{page * pageSize < total ? page * pageSize : total}}</strong> van {{total}}
      </li>
      <li *ngIf="page !== 1" class="vl-pager__element">
        <a (click)="previousPage(); false" href="#" class="vl-pager__element__cta">
          <i class="vl-link__icon vl-link__icon--before vl-vi vl-vi-arrow-left-fat" aria-hidden="true"></i>
          Vorige<span class="vl-u-visually-hidden"> 10 rijen</span>
        </a>
      </li>
      <li *ngIf="page * pageSize + pageSize < total + pageSize" class="vl-pager__element">
        <a (click)="nextPage(); false" href="#" class="vl-pager__element__cta">Volgende
          <span class="vl-u-visually-hidden"> 10 rijen</span>
          <i class="vl-link__icon vl-link__icon--after vl-vi vl-vi-arrow-right-fat" aria-hidden="true"></i>
        </a>
      </li>
    </ul>
  </div>
  `
})
export class WuPagerComponent {
  @Input() total: number;
  @Input() page: number;
  @Input() pageSize: number;

  @Output() getPage = new EventEmitter<object>();

  public nextPage(): void {
    this.changePage(this.page + 1);
  }

  public previousPage(): void {
    this.changePage(this.page - 1);
  }

  public changePage(newPage: number): void {
    this.getPage.emit({page: newPage, pageSize: this.pageSize});
  }
}
