import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl-BE';

import { AppComponent } from 'app/app.component';
import { AppRoutingModule } from 'app/app-routing.module';
import { CoreModule } from 'app/core/core.module';
import { ForbiddenComponent } from 'app/forbidden.component';
import { UnauthorizedComponent } from 'app/unauthorized.component';
import { CallbackComponent } from 'app/callback.component';
import { UserService } from 'app/user/user-shared/user-service';
import { NotfoundComponent } from 'app/notfound.component';
import { SharedModule } from 'app/shared/shared.module';
import { WebuniversumComponent } from 'app/webuniversum/webuniversum.component';

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    CallbackComponent,
    NotfoundComponent,
    WebuniversumComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule
  ],
  providers: [
    UserService,
    {
      provide: LOCALE_ID, useValue: 'nl-BE'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
