<div class="vl-page">
	<div *ngIf="!isSignedIn() || !user || !user.userContext">
		<header class="vl-content-header vl-content-header--large vl-content-header--has-logo vl-content-header--show-mobile">
			<div class="vl-content-header__wrapper">
				<picture class="vl-content-header__bg">
					<!-- TODO: get correct image in header. This is just a placeholder image -->
					<img sizes="100vw" src="../assets/img/header_bouwen_wonen.jpg" alt="Content Header" class="visual">
				</picture>

				<div class="vl-layout">
					<div class="vl-content-header__content">
						<div class="vl-content-header__line vl-content-header__line-1"></div>
						<div class="vl-content-header__line vl-content-header__line-2 js-vl-content-header__line-2"></div>
						<div class="vl-content-header__line vl-content-header__line-3 js-vl-content-header__line-3"></div>
						<div class="vl-content-header__logo">
							<div class="vl-content-header__logo__img">
								<svg role="img" aria-labelledby="title" id="leeuw" data-name="leeuw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.61 31.05">
									<title>Logo Vlaanderen</title>
									<path id="Logo_Vlaanderen" data-name="Logo Vlaanderen" d="M19.61,16c-1.08-.82-1.59,0-2.27,0s-1.12-1-1.56-.75c-0.85.43,0.34,2,.86,2.27a12.51,12.51,0,0,0,1.18.59,1.72,1.72,0,0,1,1,1.55,3,3,0,0,1,0,.75c-0.31,1.32-2.64,2.52-4,1.61a3,3,0,0,1-1.4-2c-0.36-1.73-1.62-3-2-4.7-0.25-1-.43-2.12-0.7-3.15S10.17,10,9.94,9A27.44,27.44,0,0,0,9,5.92C7.71,2.61,7.14,2.85,7.14,2.85s0.46,0.92,2.15,9A47.8,47.8,0,0,0,10.42,17c0.16,0.42.44,1.32,0.62,1.72,0.54,1.16,2,2.92,2.05,4.51,0.05,0.92.16,1.68,0.18,2.37a7.37,7.37,0,0,0,.24,1.4c0.35,1,3.14,4.09,6.1,4.09V28.68a10.88,10.88,0,0,1-5.79-1.82,6.37,6.37,0,0,1,.35-1.7,2.78,2.78,0,0,1,2.39-1.94,17.91,17.91,0,0,1,3,.31V16ZM6.82,7.36c-0.11,1.77-2.87,4.2-3.73,5.75A11.43,11.43,0,0,0,2,15.95a7.53,7.53,0,0,0,.62,4.67c0.91,2.18-.12,3,0.59,2.54,0.88-.72.75-2.39,0.68-3.43a14.77,14.77,0,0,1,0-2.82A13.29,13.29,0,0,1,6.3,11.58a4.84,4.84,0,0,0,.52-4.22m0.56,5s0.29,1.4-1,5c-3.26,9.45,3,10.34,4.74,12.56,0,0,.69-1-2.14-4-1-1.12-2-3.6-1.15-7.15,1.17-5.13-.5-6.39-0.5-6.39M1.21,6.2A4.53,4.53,0,0,1,1,4.7C1.2,2.48,3.23,1.58,3.73,1.25A2.7,2.7,0,0,0,4.76,0,3,3,0,0,1,3.55,3.67,6.06,6.06,0,0,0,1.21,6.2M6.32,4.05c0.11,0.23,1,1.45-2.48,4.27s-2.36,4.84-2.36,4.84-3.66-2,.56-5.53S5.39,3.43,5.39,3.43a1.05,1.05,0,0,1,.93.63m4.46,1.08c0.59,0.08,1,1.92,2.35,2.32,1,0.29,2.08.13,2.32,0.74a0.52,0.52,0,0,0,.37.92C16.21,8,16.4,3.88,10.79,5.14ZM12.64,6c0-.13.12,0,0.27-0.12a1.66,1.66,0,0,1,.59-0.48,1,1,0,0,1,.62,0c0.12,0,0,.33,0,0.4s-0.74-.09-0.74.32c0,0.67.91,0,1.38,0,0.23,1.64-2.48,1.19-2.08-.16h0Z" />
								</svg>

							</div>
							<div class="vl-content-header__logo__text">
								<div class="vl-content-header__logo__title">
									Toezicht sociale huisvesting
									<span class="vl-content-header__logo__tagline">
										Portaal
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	</div>

	<!-- header here -->
	<header class="vl-functional-header vl-functional-header--has-actions" role="banner" *ngIf="isSignedIn() && user && user.userContext">
		<div class="vl-layout">
			<div class="vl-functional-header__row">
				<div class="vl-functional-header__content">
					<h1 class="vl-title"><a class="vl-functional-header__title" routerLink="/startpagina">Portaal Toezicht Sociale Huisvesting</a></h1>
				</div>
				<div class="vl-functional-header__actions">
					<ul>
						<li *ngIf="isSignedIn()" class="vl-functional-header__action">
							<ng-container *ngIf="accountName()">{{ accountName() }} -</ng-container>  {{ userDisplayName() }}
						</li>
						<li *ngIf="isSignedIn() && !user.userContext.privilege.portalMedewerker" class="vl-functional-header__action">
							<a [routerLink]="['/user/selection']" [queryParams]="{intentionalNavigation: true}">Wissel van organisatie</a>
						</li>
						<li *ngIf="isSignedIn()" class="vl-functional-header__action">
							<a (click)="signOut()">Afmelden</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="vl-functional-header__sub">
				<nav class="vl-col--1-1 vl-col--12-12--m vl-col--1-1--s" data-tabs="">
					<div data-vl-tabs data-vl-tabs-responsive-label="Navigatie">
						<div class="vl-tabs__wrapper">
							<ul class="vl-tabs " data-vl-tabs-list role="tablist">
								<li class="vl-tab" routerLinkActive="vl-tab--active" [routerLinkActiveOptions]="{exact: true}">
									<a routerLink="/startpagina" class="vl-tab__link" data-vl-tab role="tab" aria-selected="true">Home</a>
								</li>
								<li *ngIf="user.userContext.privilege.bestuursvergaderingen || user.userContext.privilege.bestuursvergaderingenToewijzingscomite" class="vl-tab" routerLinkActive="vl-tab--active">
									<a routerLink="/bestuursvergaderingen" class="vl-tab__link" data-vl-tab role="tab" aria-selected="true">Bestuursvergaderingen</a>
								</li>
								<li *ngIf="user.userContext.privilege.overheidsopdrachten" class="vl-tab" routerLinkActive="vl-tab--active">
									<a routerLink="/overheidsopdrachten" class="vl-tab__link" data-vl-tab role="tab" aria-selected="true">Overheidsopdrachten</a>
								</li>
								<li *ngIf="user.userContext.privilege.bestuur || user.userContext.privilege.kapitaalstructuur" class="vl-tab" routerLinkActive="vl-tab--active" [routerLinkActiveOptions]="{exact: true}">
									<a routerLink="/bestuur" class="vl-tab__link" data-vl-tab role="tab" aria-selected="true">Bestuur</a>
								</li>
								<li *ngIf="user.userContext.privilege.globaalOnderzoek" class="vl-tab" routerLinkActive="vl-tab--active">
									<a routerLink="/globaleonderzoeken" class="vl-tab__link" data-vl-tab role="tab" aria-selected="true">Globale onderzoeken</a>
								</li>
								<li *ngIf="user.userContext.privilege.bestandsuitwisseling" class="vl-tab" routerLinkActive="vl-tab--active">
										<a routerLink="/bestandsuitwisseling" class="vl-tab__link" data-vl-tab role="tab" aria-selected="true">Bestandsuitwisseling</a>
									</li>
							</ul>
							<button type="button" data-vl-tabs-toggle aria-expanded="false" class="vl-tabs__toggle" data-vl-close="false"><span>Navigatie</span></button>
						</div>

					</div>

				</nav>
			</div>
		</div>
	</header>
	<!-- end header -->
	<app-wu-general-alert [correlationId]="correlationId" *ngIf="errorOccurred"></app-wu-general-alert>
	<div id="main" itemprop="mainContentOfPage" role="main" tabindex="-1" class="vl-main-content">
		<router-outlet *ngIf="!errorOccurred"></router-outlet>
		<app-scroll-to-top></app-scroll-to-top>
	</div>
</div>
