import { Component } from '@angular/core';

@Component({
  template: `
    <div
      class="vl-page vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center"
    >
      <div
        id="main"
        itemprop="mainContentOfPage"
        role="main"
        tabindex="-1"
        class="vl-main-content"
      >
        <div class="vl-region">
          <div class="vl-layout">
            <div
              class="vl-grid vl-grid--is-stacked vl-grid--align-center vl-grid--v-center"
            >
              <!-- content -->
              <div class="vl-col--1-2 vl-u-hidden vl-u-visible--s">
                <div
                  class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center"
                >
                  <img src="./assets/img/status/page-not-found.svg" />
                </div>
              </div>

              <div class="vl-col--1-2 vl-col--8-12--s vl-col--1-1--xs">
                <div class="vl-grid vl-grid--is-stacked">
                  <div class="vl-col--1-1">
                    <h1 class="vl-title vl-title--h2">
                      Oeps, die pagina vonden we niet terug
                    </h1>
                    <div class="vl-typography">
                      <p>De pagina die u zoekt, vonden we niet terug.</p>
                    </div>
                  </div>

                  <div class="vl-col--1-1">
                    <a href="/" class="vl-button">Terug naar de startpagina</a>
                  </div>

                  <div class="vl-col--1-1">
                    <p>
                      Blijft het probleem bestaan,
                      <!-- TODO: link contact -->
                      <a
                        href="#CTA naar formulier om een foutieve link te melden"
                        >contacteer ons</a
                      >.
                    </p>
                  </div>
                </div>
              </div>

              <div class="vl-col--1-2 vl-u-hidden--s">
                <div
                  class="vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center"
                >
                  <img src="./assets/img/status/page-not-found.svg" />
                </div>
              </div>
              <!-- end content -->
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class NotfoundComponent {}
