import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

declare var vl: any;

@Directive({
  selector: '[appWuMultiselectDirective]',
})
export class WuMultiselectDirective implements AfterViewInit {
  @Input() shouldSort = 'true';

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    const shouldSort = this.shouldSort === 'true' ? true : false;
    vl.select.globalConfig['shouldSort'] = shouldSort;
    const id = 'choices-' + this.elementRef.nativeElement.id;
    const selectInstance = vl.select.selectInstances.find(instance => instance.baseId === id);
    vl.select.selectInstances = vl.select.selectInstances.filter(instance => instance !== selectInstance);
    vl.select.dress(this.elementRef.nativeElement);
    vl.select.globalConfig['shouldSort'] = true;
  }
}
