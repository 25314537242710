import { Component, OnInit, Input } from '@angular/core';

import { ContentBlockTitlelistItem } from 'app/shared/content-block/content-block-titlelist-item';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html'
})
export class SideNavComponent {
  @Input() titleList: ContentBlockTitlelistItem[];

  scrollTo(id) {
    const el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
